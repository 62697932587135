import React from 'react'
import {Autocomplete, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {getLocaleForFields, getOptionLabel} from "../../help/lang";

export const RegionField = ({props,  isSendAnswer, regionsDictionary, t}) => {
    const locale = getLocaleForFields();
    return <Autocomplete id={"region"}
                         name={"region"}
                         fullWidth
                         options={regionsDictionary}
                         filterOptions={() => regionsDictionary.filter((v) => v.countryCode === props.values?.country?.code)}
                         getOptionLabel={(option) => getOptionLabel(locale, option, t)}
                         value={props.values?.region}
                         onBlur={props.handleBlur}
                         error={props.errors.region && props.touched.region}
                         isOptionEqualToValue={(ob1, ob2) => {
                             return ob1?.code === ob2?.code
                         }}
                         disabled={isSendAnswer || false}
                         sx={{mt: 1}}
                         onChange={(_, value) => props.setFieldValue("region", value)}
                         renderInput={(params) => (
                             <TextField {...params} label={t("Регион")} placeholder=""
                                        helperText={<ErrorMessage name="region"/>}
                             />
                         )}
    />
}
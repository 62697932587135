import {generateUserName} from "./generator";

export const getUserName = () => {
    let username = localStorage.getItem("username");
    if (!username) {
        username = generateUserName()
        saveUserName(username)
    }
    return username?.length > 0 ? username : ""
}

export const saveUserName = (value) => {
    localStorage.setItem("username", value)
}
import {Box} from "@mui/material";

export const PolaroidCard = ({image, tape}) => {
    return <Box display={"flex"} alignItems={"center"} justifyContent={"center"}
                margin={"auto"} position={"relative"}>
        <Box className={tape}/>
        <Box component="img" src={image} alt={"Tatiana is drinking wine"}
             boxShadow={3}
             sx={{
                 height: "auto",
                 width: "auto",
                 maxWidth: '20rem',
                 maxHeight: '20rem'
             }}/>
    </Box>
}
import React, {Fragment} from "react";
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {SetNextWineBtn} from "../components/SetNextWineBtn";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {UserAvatar} from "../help/avatar";
import {BottomActionsPanel, PaperItem, RedRibbon} from "../components/base";
import CopyLinkBtn from "../components/CopyLinkBtn";
import {WineDescriptionWithPicture} from "../components/wineViewUtils";
import {makeStyles} from "@mui/styles";
import {findAnswer} from "../help/utils";
import {getArea, getGrapes} from "../help/fieldsMapping";
import {getLocaleForFields} from "../help/lang";
import {useColorScheme} from "@mui/material/styles";

export const LevelResultPanel = () => {

    const {t} = useDefaultContext();
    const {uuid, currentWine, users, results} = useWSContext();

    const useStyles = makeStyles((theme) => ({
        head: {
            position: "sticky", zIndex: 2, top: 0, backgroundColor: theme.palette.background.default,
        },
        row: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover
            },
        },
        angle_sticky_cell: {
            position: "sticky",
            zIndex: 2,
            left: 0,
            backgroundColor: theme.palette.background.default,
        },
        sticky_cell: {
            position: "sticky", zIndex: 1, left: 0, backgroundColor: theme.palette.background.default,

        }, footer: {
            bottom: 0, zIndex: 2, position: "sticky", backgroundColor: theme.palette.background.default,
        }
    }));

    const classes = useStyles();
    const {mode, setMode} = useColorScheme();
    const locale = getLocaleForFields();

    const getColor = (a, w) => {
        return a === w ? "green" : mode === "dark" ? "white" : "black"
    }

    const getColorLabel = (a, w) => {
        return <Typography color={getColor(a, w)}>{a}</Typography>
    }

    const mapAnswer = (answer, wine) => {
        const a_grapes = getGrapes(answer, t, locale);
        const w_grapes = getGrapes(wine, t, locale);

        const a_area = getArea(answer, t, locale);
        const w_area = getArea(wine, t, locale);

        return <Box display={"flex"} flexDirection={"column"}>
            {answer && <Fragment>
                {getColorLabel(a_grapes, w_grapes)}
                {getColorLabel(a_area, w_area)}
                {getColorLabel(answer?.vintage, wine?.vintage)}
                <Typography color={getColor(answer?.alcohol, wine?.alcohol)}>{answer?.alcohol}%</Typography>
            </Fragment>}
            {!answer && <Typography typography={'caption'}>{t("-----")}</Typography>}
        </Box>
    }
    return <Fragment>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flexGrow={1} p={1} gap={1}>
            <PaperItem key={currentWine.uuid} elevation={6} sx={{margin: 'auto'}}>
                <Stack direction={"column"} sx={{p: 1, height: '100%', width: "fit-content"}}>
                    <RedRibbon text={currentWine?.name}/>
                    <WineDescriptionWithPicture wine={currentWine}/>
                </Stack>
            </PaperItem>
            <PaperItem key={`results_${currentWine.uuid}`} elevation={6} sx={{margin: 'auto'}}>

                <Box display={"flex"} flexDirection={"column"} flexGrow={1}
                     justifyContent={"center"}
                     margin={"auto"} width={"90vw"}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {users.map((user) => {
                                    let result = "Нет ответа";
                                    let answer = findAnswer(results, user, currentWine)
                                    if (answer?.uuid !== undefined) {
                                        result = mapAnswer(answer, currentWine)
                                    }
                                    return <TableRow key={`results_${user.uuid}`} className={classes.row}>
                                        <TableCell className={classes.sticky_cell} key={user?.uuid}>
                                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                                <UserAvatar user={user} size={64}/>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center" key={`${currentWine?.uuid}_${user?.uuid}`}>
                                            {result}
                                        </TableCell>
                                        <TableCell align="center" key={`points_${user.uuid}`}>
                                            <Typography variant={"h6"}>{answer.points || 0}</Typography></TableCell>
                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </PaperItem>

        </Box>
        <BottomActionsPanel>
            <CopyLinkBtn uuid={uuid} t={t}/>
            <SetNextWineBtn/>
        </BottomActionsPanel>

    </Fragment>
}
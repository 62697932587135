import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AppBar, Box, CssBaseline, darkScrollbar, Toolbar,} from "@mui/material";
import {
    Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import {grey} from "@mui/material/colors";
import NotFoundPage from "./pages/NotFoundPage";
import DegustationPage from "./pages/DegustationPage";
import {ModeSwitch} from "./components/ModeSwitch";
import {LanguageComponent} from "./components/LanguageComponent";
import {useTranslation} from "react-i18next";
import {DefaultProvider} from "./context/WSContext";
import {LoginPage} from "./pages/LoginPage";
import {FaqBtn} from "./components/FaqBtn";
import {FaqPage} from "./pages/FaqPage";
import {DashboardPage} from "./pages/DashboardPage";
import {AccountMenu} from "./components/AccountMenu";
import {ProfilePage} from "./pages/ProfilePage";
import {FlexMainPage} from "./components/base";

const theme = extendTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    iframe: {
                        colorScheme: "none"
                    }
                },
                html: {
                    ...darkScrollbar((theme) => theme.palette.mode === "light" ? {
                        track: grey[200], thumb: grey[400], active: grey[400],
                    } : undefined), //scrollbarWidth for Firefox
                    scrollbarWidth: "thin",
                }, backgroundColor: (theme) => theme.palette.mode === 'light' ? grey[200] : grey[800],
            }
        }, MuiButton: {
            styleOverrides: {
                root: {maxWidth: '140px'}
            }
        },
    },
})

function App() {

    const {t} = useTranslation();
    const [currentUser, setCurrentUser] = useState({});
    const [isLogin, setIsLogin] = useState(false)

    useEffect(() => {
        const legacyAccessToken = localStorage.getItem("accessToken")
        if (legacyAccessToken) {
            localStorage.removeItem("accessToken")
        }
    });
    return (<CssVarsProvider defaultMode="dark" theme={theme}>
            <CssBaseline enableColorScheme/>
            <Router>
                <DefaultProvider value={{t, isLogin, setIsLogin, currentUser, setCurrentUser}}>
                    <FlexMainPage>
                        <AppBar position="sticky">
                            <Toolbar variant="dense">
                                <Box display={"flex"} flexGrow={1} alignItems={"center"}>
                                    <ModeSwitch/>
                                    <LanguageComponent/>
                                    <FaqBtn/>
                                </Box>
                                <Box display={"flex"} alignItems={"center"}>
                                    {
                                        isLogin && <AccountMenu/>
                                    }
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <Routes>
                            <Route path="dashboard" exact element={<DashboardPage/>}/>
                            <Route path=":degustationUUID" element={<DegustationPage/>}/>
                            <Route path="login?" exact element={<LoginPage/>}/>
                            <Route path="profile" exact element={<ProfilePage/>}/>
                            <Route path="faq" exact element={<FaqPage/>}/>
                            <Route path='*' element={<NotFoundPage/>}/>
                        </Routes>
                    </FlexMainPage>
                </DefaultProvider>
            </Router>
        </CssVarsProvider>

    );
}

export default App;
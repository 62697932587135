import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {styled} from "@mui/styles";

export const PaperItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

export const TopActionsPanel = ({children}) => {
    return <Box key={"top_actions_panel"} display={"flex"} flexDirection={"row"} width={"100%"}
                justifyContent={"flex-start"}
                gap={2}>
        {children}
    </Box>
}

export const BottomActionsPanel = ({children}) => {
    return <Box key={"bottom_actions_panel"}
                sx={{position: "sticky", bottom: 0, zIndex: 3}}
                display={"flex"} flexDirection={"row"}
                width={"100%"}
                justifyContent={"flex-end"}
                gap={1} p={1}>
        {children}
    </Box>
}

export const BreakWordTypography = ({text, maxWidth}) => {
    return <Typography typography={'caption'} align={"center"}
                       sx={{
                           maxWidth: maxWidth ? maxWidth : '5rem',
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                           display: "-webkit-box",
                           WebkitBoxOrient: "vertical",
                           wordBreak: 'break-word',
                       }}>{text}</Typography>
}

export const FlexMainPage = ({children, sx}) => {
    return <Box display={"flex"} flexDirection={"column"} flex={1} overflow={"auto"} justifyContent={"flex-start"} sx={{...sx}}>
        <Box display={"flex"} flexDirection={"column"} flex={1} justifyContent={"flex-start"}>
            {children}
        </Box>
    </Box>
}

export const FlexTablePanel = ({emptyMessage, children}) => {
    return <Box display={"flex"} flexDirection={"column"} p={1} flexGrow={1}>
        {
            children?.length > 0 && <Box display={"grid"}
                                         gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
                                         gridAutoRows={"max-content"}
                                         gap={1}>
                {children}
            </Box>
        }
        {
            (!children || children?.length === 0) &&
            <Box display={"flex"} flexDirection={"column"} flex={1} flexGrow={1} textAlign={"center"}
                 justifyContent={"center"}>
                <Typography variant={"h5"}>{emptyMessage}</Typography>
            </Box>
        }
    </Box>
}

export const RedRibbon = ({text}) => {
    return <Box sx={{
        minWidth: "100%",
        '--s': '1.8em', /* the ribbon size */
        '--d': '.8em', /* the depth */
        '--c': '.8em',  /* the cutout part */
        textAlign: "center",
        padding: '0 calc(var(--s) + .5em) var(--d)',
        lineHeight: 1.8,
        background: 'conic-gradient(at left  var(--s) bottom var(--d),' + '#0000 25%,#0008 0 37.5%,#0004 0) 0   /50% 100% no-repeat,' + 'conic-gradient(at right var(--s) bottom var(--d),' + '#0004 62.5%,#0008 0 75%,#0000 0) 100%/50% 100% no-repeat;',
        clipPath: 'polygon(0 var(--d), var(--s) var(--d),var(--s) 0,calc(100% - var(--s)) 0,calc(100% - var(--s)) var(--d),100% var(--d),' +
            'calc(100% - var(--c)) calc(50% + var(--d)/2),100% 100%,calc(100% - var(--s) - var(--d)) 100%,calc(100% - var(--s) - var(--d)) calc(100% - var(--d)),' +
            'calc(var(--s) + var(--d)) calc(100% - var(--d)),calc(var(--s) + var(--d)) 100%,0 100%,var(--c) calc(50% + var(--d)/2));',
        backgroundColor: '#CC333F',
        width: 'fit-content'
    }}>
        {text}
    </Box>
}

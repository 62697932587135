import i18n from "../i18n";

const WineNouns = 10;
const WineAdjectives = 12;
const UserNouns = 8;
const UserAdjectives = 3;

export const generateWineName = () => {
    const noun_idx = Math.floor(WineNouns * Math.random());
    const adj_idx = Math.floor(WineAdjectives * Math.random());
    const noun = i18n.t(`nameService.wine.nouns.${noun_idx}`);
    const adjective = i18n.t(`nameService.wine.adjectives.${adj_idx}`, {
        noun: noun.value,
    });
    return adjective[noun.class] ?? adjective.mfn;
}

export const generateUserName = () => {
    const noun_idx = Math.floor(UserNouns * Math.random());
    const adj_idx = Math.floor(UserAdjectives * Math.random());
    const noun = i18n.t(`nameService.user.nouns.${noun_idx}`);
    const adjective = i18n.t(`nameService.user.adjectives.${adj_idx}`, {
        noun: noun.value,
    });
    return adjective[noun.class] ?? adjective.mfn;
}
import React, {useState} from "react";
import {Button, Stack, Paper, Box} from "@mui/material";
import {Form, Formik} from "formik";
import {CountryField} from "../fields/CountryField";
import {RegionField} from "../fields/RegionField";
import {VintageField} from "../fields/VintageField";
import {AlcoholField} from "../fields/AlcoholField";
import {GrapesField} from "../fields/GrapesField";
import {addAnswer} from "../../requests/api";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import * as Yup from "yup";
import {maxPercent, maxVintage, minPercent, minVintage} from "../../help/constants";
import {RedRibbon} from "../base";
import {WineDescriptionWithPicture} from "../wineViewUtils";

export const AnswerForm = () => {
    const {
        t, regionsDictionary, countriesDictionary
    } = useDefaultContext();
    const {currentWine, uuid, currentAnswer, wines} = useWSContext();

    const [isSendAnswer, setIsSendAnswer] = useState(!!currentAnswer?.uuid);
    const openLength = wines.filter(wine => wine.state === 'OPENED').length + 1

    const handleSubmit = (values, props) => {
        if (!isSendAnswer) {
            addAnswer(uuid, values).then(response => {
                setIsSendAnswer(true);
            })
        }
    }

    const validationSchema = Yup.object({
        alcohol: Yup.number().required().min(minPercent).max(maxPercent),
        vintage: Yup.number().required().min(minVintage).max(maxVintage).integer(),
        grapes: Yup.array().of(Yup.object().shape({})).required().min(1).max(3)
    });
    return (<Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
        <Formik initialValues={currentAnswer} validationSchema={validationSchema}
                onSubmit={handleSubmit}>{(props) => (<Form>
            <Paper elevation={3} sx={{ mt:1, p: 1}}>
                <Stack direction="column">
                    <RedRibbon text={openLength + "/" + wines.length + " " + currentWine?.name}/>
                    <WineDescriptionWithPicture wine={currentWine}/>
                </Stack>
                <Stack direction="column" spacing={0.5}>
                    <GrapesField props={props} isSendAnswer={isSendAnswer}/>
                    <Stack direction="row" spacing={0.5}>
                        <AlcoholField props={props} isSendAnswer={isSendAnswer} t={t}/>
                        <VintageField props={props} isSendAnswer={isSendAnswer} t={t}/>
                    </Stack>
                    <CountryField props={props} isSendAnswer={isSendAnswer} t={t}
                                  countriesDictionary={countriesDictionary}/>
                    <RegionField props={props} isSendAnswer={isSendAnswer} t={t}
                                 regionsDictionary={regionsDictionary}/>
                </Stack>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    {isSendAnswer && <Button type={"submit"}
                                             variant="contained" fullWidth size={"medium"}
                                             onClick={() => setIsSendAnswer(false)}>{t("Редактировать")}</Button>}
                    {!isSendAnswer && <Button type={"submit"}
                                              variant="contained" fullWidth
                                              size={"medium"}>{t("Сохранить")}</Button>}
                </Box>
            </Paper>
        </Form>)}
            </Formik>
            </Box>);
        }

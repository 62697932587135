import React from 'react';
import {deleteWine} from "../../requests/api";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import {PaperItem, RedRibbon} from "../base";
import {WineDescriptionWithPicture} from "../wineViewUtils";
import DeleteIcon from '@mui/icons-material/Delete';

export const WineCard = ({wine}) => {

    const {uuid} = useWSContext();
    const {currentUser, t} = useDefaultContext();

    const isOwnerOfWine = currentUser?.uuid === wine?.owner?.uuid;
    const handleDelete = () => {
        if (isOwnerOfWine) {
            deleteWine(uuid, wine.uuid).then()
        }
    };

    return <PaperItem key={wine.uuid} elevation={6}>
        <Stack direction={"column"} sx={{p: 1, height: '100%'}}>
            <RedRibbon text={wine?.name}/>
            <WineDescriptionWithPicture wine={wine}>
                <Tooltip title={t("Удалить вино может только его владелец")}>
                    <span>
                        <IconButton size="medium" disabled={!isOwnerOfWine} onClick={handleDelete}>
                        <DeleteIcon fontSize="inherit" color={isOwnerOfWine ? "error" : "disabled"}/></IconButton>
                    </span>
                </Tooltip>
            </WineDescriptionWithPicture>
        </Stack>
    </PaperItem>
}

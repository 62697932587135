import React from "react";
import {useColorScheme,} from '@mui/material/styles';
import {Switch, FormControl} from "@mui/material";
import {Brightness4, Brightness5} from "@mui/icons-material";

export const ModeSwitch = () => {
    const {mode, setMode} = useColorScheme();
    return (<FormControl>
            <Switch disableRipple
                    color={"default"}
                    checkedIcon={<Brightness4 fontSize="small" style={{color: "white"}}/>}
                    icon={<Brightness5 fontSize="small" style={{color: "white"}}/>}
                    checked={mode === 'light'}
                    onChange={() => {
                        setMode(mode === 'light' ? 'dark' : 'light');
                    }}
            /></FormControl>
    );
}
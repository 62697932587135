import React, {Fragment} from "react";
import CopyLinkBtn from "../CopyLinkBtn";
import {SetNextWineBtn} from "../SetNextWineBtn";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import {WineCard} from "./WineCard";
import {AddWineBtn} from "./AddWineBtn";
import {
    BottomActionsPanel, FlexTablePanel,
} from "../base";

export const AddWinePanel = () => {
    const {t} = useDefaultContext();
    const {wines} = useWSContext();

    return <Fragment>
        <FlexTablePanel emptyMessage={`${t("Вы не добавили ни одного вина")} 🍷`}>
            {wines?.map(wine => <WineCard key={wine.uuid} wine={wine}/>)}
        </FlexTablePanel>
        <BottomActionsPanel>
            <CopyLinkBtn/>
            <AddWineBtn/>
            <SetNextWineBtn/>
        </BottomActionsPanel>
    </Fragment>
}
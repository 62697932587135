import React from "react";
import {IconButton, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {maxVintage, minVintage} from "../../help/constants";
import {AddBox, IndeterminateCheckBox} from "@mui/icons-material";

export const VintageField = ({props, isSendAnswer, t}) => {
    return <TextField id={"vintage"}
                      name={"vintage"}
                      fullWidth
                      variant="outlined"
                      required
                      label={t("Год")}
                      value={props.values?.vintage}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      helperText={<ErrorMessage name={"vintage"}/>}
                      error={props.errors.vintage && props.touched.vintage}
                      disabled={isSendAnswer || false}
                      InputProps={{
                          type: "number",
                          sx: {
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                  display: 'none'
                              },
                              '& input[type=number]': {
                                  MozAppearance: 'textfield'
                              },
                          },
                          startAdornment: (
                              <IconButton
                                  onClick={() => props.setFieldValue("vintage", props.values?.vintage - 1)}
                                  disabled={(props.values?.vintage <= minVintage) || isSendAnswer}>
                                  <IndeterminateCheckBox/>
                              </IconButton>
                          ),
                          endAdornment: (
                              <IconButton
                                  onClick={() => props.setFieldValue("vintage", props.values?.vintage + 1)}
                                  disabled={(props.values?.vintage >= maxVintage) || isSendAnswer}>
                                  <AddBox/>
                              </IconButton>
                          ),
                      }}/>
}
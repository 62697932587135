import React, {Fragment} from "react";
import {
    Button,
    useMediaQuery,
    Dialog,
    DialogContent,
    DialogActions,
    Fab,
    Box,
    DialogTitle
} from "@mui/material";
import {QRCodeSVG} from "qrcode.react";
import {useTheme} from "@mui/material/styles";
import {useDefaultContext} from "../context/WSContext";
import QrCode2SharpIcon from '@mui/icons-material/QrCode2Sharp';

export default function CopyLinkBtn() {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {t} = useDefaultContext();

    const handleClickOpen = () => setOpen(true);
    const handleClose = (value: string) => setOpen(false);

    return (<Fragment>
            <Fab size="large" color="secondary" aria-label="copy link" onClick={handleClickOpen}>
                <QrCode2SharpIcon/>
            </Fab>
            <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
                <DialogTitle textAlign="center">{t("QR-код дегустации")}</DialogTitle>
                <DialogContent dividers>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-around"}
                         flexGrow={1} gap={2}>
                        <QRCodeSVG size={300}
                                   style={{margin: '20px'}}
                                   bgColor={theme.palette.background.default} fgColor={theme.palette.text.primary}
                                   value={window.location.href}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color={"error"} sx={{minWidth: '100%'}}
                            variant="contained" fullWidth
                            size={"medium"}
                            onClick={handleClose}>{t("Закрыть")}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
import React, {useEffect, useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {PaperItem} from "../components/base";
import {useNavigate} from "react-router-dom";
import {useDefaultContext} from "../context/WSContext";
import {ErrorMessage, Form, Formik} from "formik";
import {UserAvatar} from "../help/avatar";
import {editUser} from "../requests/api";

export const ProfilePage = () => {
    const navigate = useNavigate();
    const {isLogin, t, currentUser} = useDefaultContext();
    const [isDisabled, setIsDisabled] = useState(true)

    const handleSubmit = (values, props) => {
        if (!isDisabled) {
            props.setValues(values);
            editUser(values).then(r => console.log(r))
        }
        setIsDisabled(!isDisabled)
    }

    useEffect(() => {
        if (!isLogin) {
            navigate("/login")
        }
    }, [isLogin, navigate]);

    return <Box display={"flex"} flexDirection={"column"} flexGrow={"1"} gap={1} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"row"} gap={2} flexGrow={1}
             justifyContent={"center"} alignItems={"center"}>

            <PaperItem key={"profile_card"} p={5}>
                <Box display={"flex"} flexDirection={"column"} p={2} gap={2}
                     justifyContent={"center"}
                     alignItems={"center"}>
                    <Box sx={{all: "initial"}}>
                        <UserAvatar user={currentUser} size={64}/>
                    </Box>
                    <Formik initialValues={currentUser}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}>{(props) => {
                        return (
                            <Form>
                                <Box display={"flex"} flexDirection={"column"} alignContent={"center"}
                                     alignItems={"center"} m={1}>
                                    <TextField id={"username"} name={"username"} label={t("Никнейм")} required
                                               variant="outlined" margin="dense" fullWidth
                                               value={props.values.username || ''}
                                               disabled={isDisabled}
                                               onChange={props.handleChange}
                                               onBlur={props.handleBlur}
                                               helperText={<ErrorMessage name={"username"}/>}
                                               error={props.errors.username && props.touched.username}
                                    />
                                    {
                                        currentUser?.accountType !== "ANONYMOUS" &&
                                        <TextField id={"email"} name={"email"} label={t("Email")} required
                                                   variant="outlined" margin="dense" fullWidth
                                                   value={props.values.email || ''}
                                                   disabled={true}
                                                   onChange={props.handleChange}
                                                   onBlur={props.handleBlur}
                                                   helperText={<ErrorMessage name={"email"}/>}
                                                   error={props.errors.email && props.touched.email}
                                        />
                                    }

                                    <Button type={"submit"}
                                            variant="contained" fullWidth
                                            size={"medium"}>{t(isDisabled ? "Редактировать" : "Сохранить")}</Button>
                                </Box>

                            </Form>)
                    }}
                    </Formik>
                </Box>
            </PaperItem>
        </Box>

    </Box>
}
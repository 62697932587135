import React from 'react';

export const getLocaleForFields = () => {
    const language = localStorage.getItem("language");
    return !language || language === "us" ? "en" : language;
}

export const getOptionLabel = (locale, option, t) => {
    if (!option) return "";
    return option[locale + "Name"] || t(option.ruName)
}

export const getLanguage = () => {
    const lang = localStorage.getItem("language");
    return lang?.length > 0 ? lang : "us"
}
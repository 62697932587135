import React, {useState} from 'react';
import {FormControl, MenuItem, Select} from "@mui/material";
import ReactCountryFlag from "react-country-flag"
import i18n from "../i18n";
import {getLanguage} from "../help/lang";

export const LanguageComponent = () => {

    const [language, setLanguage] = useState(getLanguage());

    const saveLanguage = (language) => {
        localStorage.setItem("language", language)
        setLanguage(language)
        i18n.changeLanguage(language)
    }
    const style = {
        fontSize: '1.5em',
        lineHeight: '1.5em',
    }
    return <FormControl>
        <Select id="language-select"
                value={language}
                onChange={(e) => {
                    saveLanguage(e.target.value);
                }}>
            <MenuItem value={"us"}><ReactCountryFlag countryCode="us" svg style={style}/></MenuItem>
            <MenuItem value={"ru"}><ReactCountryFlag countryCode="ru" svg style={style}/></MenuItem>
        </Select>
    </FormControl>
}

import React, {Fragment} from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import {getGrapes} from "../help/fieldsMapping";
import {getLocaleForFields, getOptionLabel} from "../help/lang";
import {useDefaultContext} from "../context/WSContext";

const locale = getLocaleForFields();
const secret = "*****"

export const WineDescription = ({wine}) => {
    const {currentUser, t} = useDefaultContext();
    const isOwner = currentUser?.uuid === wine?.owner?.uuid;
    const isOpened = wine?.state === 'OPENED'

    return <Stack direction={"column"} flexGrow={1} justifyContent={"center"} alignItems={"flex-start"}>
        <Typography
            textAlign={"justify"}><strong>{t("Виноград")}: </strong>{isOpened || isOwner ? getGrapes(wine, t, locale) : secret}
        </Typography>
        <Typography
            textAlign={"justify"}><strong>{t("Страна")}: </strong>{isOpened || isOwner ? getOptionLabel(locale, wine?.country, t) : secret}
        </Typography>
        <Typography
            textAlign={"justify"}><strong>{t("Регион")}: </strong>{isOpened || isOwner ? getOptionLabel(locale, wine?.region, t) || t("Не указан") : secret}
        </Typography>
        <Typography textAlign={"justify"}><strong>{t("Год")}: </strong>{isOpened || isOwner ? wine?.vintage : secret}
        </Typography>
        <Typography
            textAlign={"justify"}><strong>{t("Алкоголь")}: </strong>{isOpened || isOwner ? wine?.alcohol : secret}
        </Typography>
    </Stack>
}

export const WineDescriptionWithPicture = ({wine, children}) => {
    return <Stack direction={"column"} flexGrow={1}>
        <Stack direction={"row"} flexGrow={1}>
            <Box component="img" src={"/bottle.png"} alignItems={"flex-start"}
                 sx={{
                     width: "auto",
                     height: "auto",
                     maxWidth: '10rem',
                     maxHeight: '10rem'
                 }}/>
            <WineDescription wine={wine} />
        </Stack>
        {
            children && <Fragment>
                <Divider sx={{mt: 1}}/>
                <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"center"}>
                    {
                        children
                    }
                </Stack>
            </Fragment>
        }


    </Stack>
}
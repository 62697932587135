import React, {Fragment} from "react";
import {Avatar, Badge, Box, Fab, Paper, Stack, Tooltip} from "@mui/material";
import {AnswerForm} from "./AnswerForm";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import CopyLinkBtn from "../CopyLinkBtn";
import {openWine} from "../../requests/api";
import {BottomActionsPanel, BreakWordTypography} from "../base";
import {UserAvatar} from "../../help/avatar";
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import WineBarIcon from '@mui/icons-material/WineBar';

const AnswerViewCard = ({user, answer}) => {
    const {
        t
    } = useDefaultContext();
    return <Paper elevation={3} sx={{p: 1, width: '7rem', height: '7rem'}}>
        <Stack alignItems="center">
            {
                user ? <Badge color={answer ? "success" : "info"}
                              badgeContent={answer ? "✔" : <b>?</b>} overlap="circular"
                              anchorOrigin={{
                                  vertical: 'bottom', horizontal: 'right',
                              }}>
                    <UserAvatar user={user} size={64}/>
                </Badge> : <Avatar sx={{width: 64, height: 64}} children={<WineBarIcon/>}/>
            }
            <BreakWordTypography text={user?.username || t("Пустой слот")}/>
        </Stack>
    </Paper>
}

const AnswerPanel = () => {
    const {users, results, currentWine} = useWSContext();
    const fillEmpties = () => {
        const countOfEmptyCards = 10 - users.length;
        const result = []
        if (countOfEmptyCards > 0) {
            for (let i = 0; i < countOfEmptyCards; i++) {
                result.push(<AnswerViewCard key={i}/>)
            }
        }
        return result
    }
    return <Box display={"flex"} flexDirection={"row"} sx={{overflowX: "auto", overflowY: "none"}}
                width={"100%"} height={"100%"} pt={1}>
        <Box display={"flex"} flexDirection={"row"} gap={1} margin={"auto"}>
            {
                users.map(user => {
                    const answer = results[user.uuid]?.find(a => a.wine.uuid === currentWine?.uuid)
                    return <AnswerViewCard answer={answer} user={user} key={user?.uuid}/>
                })
            }
            {
                fillEmpties()
            }
        </Box>
    </Box>
}

export const AddAnswerPanel = () => {
    const {
        currentUser, t,
    } = useDefaultContext();
    const {currentWine, uuid, owner, answers} = useWSContext();

    const canOpenWine = owner?.uuid === currentUser.uuid && answers?.length !== 0;

    return <Fragment>
        <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
            <AnswerForm/>
            <AnswerPanel/>
        </Box>
        <BottomActionsPanel>
            <CopyLinkBtn uuid={uuid} t={t}/>
            <Tooltip title={t("Открыть вино может только владелец дегустации")}>
                <span>
                    <Fab size="large" color="success" aria-label="open the wine"
                         disabled={!canOpenWine} onClick={() => openWine(uuid, currentWine?.uuid)}>
                             <PlayArrowSharpIcon/>
                    </Fab>
                </span>
            </Tooltip>
        </BottomActionsPanel>
    </Fragment>
}
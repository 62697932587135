import {Box, Typography, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function NotFoundPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (<Box display={"flex"} flexDirection={"column"}
                 justifyContent={"center"} alignItems={"center"}
                 minHeight={"70vh"}>
            <Typography variant="h1">
                404
            </Typography>
            <Typography variant="h6">
                {t("Страница не найдена")}
            </Typography>
            <Button variant="contained" fullWidth onClick={() => navigate("")}
                    sx={{maxWidth: '140px'}}>{t("Назад")}</Button>
        </Box>
    )
}

export default NotFoundPage;
import React, {Fragment} from "react";
import {makeStyles} from "@mui/styles";
import {useColorScheme, useTheme} from "@mui/material/styles";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography, useMediaQuery
} from "@mui/material";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {getArea, getGrapes} from "../help/fieldsMapping";
import {UserAvatar} from "../help/avatar";
import {getLocaleForFields} from "../help/lang";
import {WineDescription, WineDescriptionWithPicture} from "./wineViewUtils";
import {findAnswer, findAnswers} from "../help/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '80vh',
    },
    row: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        }
    },
    head: {
        position: "sticky",
        zIndex: 2,
        top: 0,
        backgroundColor: theme.palette.background.default,
    },
    angle_sticky_cell: {
        position: "sticky",
        zIndex: 2,
        left: 0,
        backgroundColor: theme.palette.background.default,
    },
    sticky_cell: {
        position: "sticky",
        zIndex: 1,
        left: 0,
        backgroundColor: theme.palette.background.default,
    },
    footer: {
        bottom: 0,
        zIndex: 2,
        position: "sticky",
        backgroundColor: theme.palette.background.default,
    }
}));


export const ResultTable = () => {
    const {t} = useDefaultContext();
    const {wines, users, results} = useWSContext();
    const theme = useTheme();
    const classes = useStyles();
    const {mode, setMode} = useColorScheme();
    const locale = getLocaleForFields();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const getColor = (a, w) => {
        return a === w ? "green" : mode === "dark" ? "white" : "black"
    }

    const getColorLabel = (a, w) => {
        return <Typography color={getColor(a, w)}>{a}</Typography>
    }

    const mapAnswer = (answer, wine) => {
        const a_grapes = getGrapes(answer, t, locale);
        const w_grapes = getGrapes(wine, t, locale);

        const a_area = getArea(answer, t, locale);
        const w_area = getArea(wine, t, locale);

        return <Box display={"flex"} flexDirection={"column"}>
            {answer && <Fragment>
                {getColorLabel(a_grapes, w_grapes)}
                {getColorLabel(a_area, w_area)}
                {getColorLabel(answer?.vintage, wine?.vintage)}
                <Typography color={getColor(answer?.alcohol, wine?.alcohol)}>{answer?.alcohol}%</Typography>
            </Fragment>}
            {!answer && <Typography typography={'caption'}>{t("-----")}</Typography>}
        </Box>
    }

    return <TableContainer className={classes.root}>
        <Table aria-label="sticky table">
            <TableHead className={classes.head}>
                <TableRow className={classes.row}>
                    <TableCell className={classes.angle_sticky_cell} key={"sticky_cell_1"}>
                        <Typography variant={"h6"}>{t("Вино")}</Typography>
                    </TableCell>
                    {
                        users.map((user) => {
                            return <TableCell className={classes.sticky_cell} key={user?.uuid}>
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                    <UserAvatar user={user} size={64}/>
                                </Box>
                            </TableCell>
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    wines.map((wine) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={`row_${wine?.uuid}`} className={classes.row}>
                                <TableCell key={wine?.uuid}  className={classes.sticky_cell}>
                                    {
                                        !fullScreen && <WineDescriptionWithPicture wine={wine}/>
                                    }
                                    {
                                        fullScreen && <WineDescription wine={wine}/>
                                    }
                                </TableCell>
                                {
                                    users.map(user => {
                                        let result = "Нет ответа";
                                        let answer = findAnswer(results, user, wine)
                                        if (answer?.uuid !== undefined) {
                                            result = mapAnswer(answer, wine)
                                        }
                                        return (
                                            <TableCell align="center" key={`${wine?.uuid}_${user?.uuid}`}>
                                                {result}
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        );
                    })}
            </TableBody>
            <TableFooter className={classes.footer}>
                <TableRow>
                    <TableCell className={classes.sticky_cell}>
                        <Typography variant={"h6"}>{t("Итого")}</Typography>
                    </TableCell>

                    {
                        users.map((user, i) => {
                            const points = findAnswers(results, user).map(a => a.points).reduce((a, b) => a + b, 0);
                            return <TableCell align="center" key={`points_${i}`}>
                                <Typography variant={"h6"}>{points}</Typography></TableCell>
                        })
                    }

                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
}
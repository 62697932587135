export const defaultVintage = 2006;
export const minVintage = new Date().getFullYear() - 100;
export const maxVintage = new Date().getFullYear();
export const defaultPercent = 12.5;
export const minPercent = 0;
export const maxPercent = 100;

export const defaultAnswer = {
    name: '',
    vintage: defaultVintage,
    alcohol: defaultPercent,
    grapes: [],
    country: null,
    region: null
}
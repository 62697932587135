import React, {Fragment} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Stack,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import {addWine} from "../../requests/api";
import * as Yup from "yup";
import {defaultPercent, defaultVintage, maxPercent, maxVintage, minPercent, minVintage} from "../../help/constants";
import {generateWineName} from "../../help/generator";
import {Form, Formik} from "formik";
import {NameField} from "../fields/NameField";
import {GrapesField} from "../fields/GrapesField";
import {AlcoholField} from "../fields/AlcoholField";
import {VintageField} from "../fields/VintageField";
import {CountryField} from "../fields/CountryField";
import {RegionField} from "../fields/RegionField";

export const AddWineBtn = () => {
    const theme = useTheme();
    const [formOpen, setFormOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleOpen = () => setFormOpen(true)
    const handleClose = (value: string) => setFormOpen(false);
    const {regionsDictionary, countriesDictionary, grapesDictionary, t} = useDefaultContext();
    const {uuid} = useWSContext();

    const handleSubmit = (values, props) => {
        addWine(uuid, {...values}).then(response => {
            props.resetForm()
        })
        handleClose()
    }
    const validationSchema = Yup.object({
        name: Yup.string().trim().required().min(1).max(30),
        alcohol: Yup.number().required().min(minPercent).max(maxPercent),
        vintage: Yup.number().required().min(minVintage).max(maxVintage).integer(),
        grapes: Yup.array().of(
            Yup.object().shape({})
        ).required().min(1).max(3)
    });

    const defaultWine = {
        name: generateWineName(),
        vintage: defaultVintage,
        alcohol: defaultPercent,
        grapes: [],
        country: null,
        region: null
    }
    return (<Fragment>
            <Fab size="large" color="primary" aria-label="add wine" onClick={handleOpen}><AddIcon/></Fab>
            <Dialog open={formOpen} onClose={handleClose} fullScreen={fullScreen}>
                <DialogTitle textAlign="center">{t("Добавление вина")}</DialogTitle>
                <DialogContent dividers>
                    <Formik initialValues={defaultWine} onSubmit={handleSubmit}
                            validationSchema={validationSchema}>{(props) => (
                        <Form>
                            <Stack direction="row" spacing={2}>
                                <NameField props={props}/>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <GrapesField props={props} grapesDictionary={grapesDictionary} t={t}/>
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{mt: 0.5}}>
                                <AlcoholField props={props} t={t}/>
                                <VintageField props={props} t={t}/>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <CountryField props={props} countriesDictionary={countriesDictionary} t={t}/>
                                <RegionField props={props} regionsDictionary={regionsDictionary} t={t}/>
                            </Stack>
                            <DialogActions sx={{justifyContent: "space-between", padding: 0, pt: 1}}>
                                <Button variant="contained" color={"error"}
                                        fullWidth
                                        onClick={handleClose}>{t("Закрыть")}</Button>
                                <Button type={"submit"} variant="contained" color={"success"}
                                        fullWidth
                                        onClick={handleClose}>{t("Сохранить")}</Button>
                            </DialogActions>
                        </Form>
                    )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
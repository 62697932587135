import React, {useEffect} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useDefaultContext} from "../context/WSContext";
import {useLocation, useNavigate} from "react-router-dom";
import {GoogleLogin} from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';

import {
    generateAnonToken,
    postLoginToken,
} from "../requests/api";
import {FlexMainPage, PaperItem} from "../components/base";

export const LoginPage = () => {

    const {state: initState} = useLocation();
    const navigate = useNavigate();
    const {isLogin, setCurrentUser, setIsLogin, t} = useDefaultContext();

    useEffect(() => {
        if (isLogin) {
            if (initState && initState.degustationUUID) {
                navigate(`/${initState.degustationUUID}`)
            } else {
                navigate('/dashboard')
            }
        }
    }, [isLogin, initState])

    const onGoogleSuccess = (response) => {
        const {credential} = response;
        postLoginToken(credential, "GOOGLE")
            .then((result) => {
                setCurrentUser(result)
                setIsLogin(!!result)
                navigate(!initState.degustationUUID ? '/dashboard' : "/" + initState.degustationUUID)
            })
            .catch((e) => {
                console.log(e)
            });
    };

    const onGoogleError = (error) => {
        console.log(error);
    };

    const onAnonymousLogin = () => {
        generateAnonToken()
            .then(() => {
                let anonToken = localStorage.getItem("anonToken");
                postLoginToken(anonToken, "ANONYMOUS")
                    .then((result) => {
                        setCurrentUser(result)
                        setIsLogin(!!result)
                        navigate(!initState.degustationUUID ? '/dashboard' : "/" + initState.degustationUUID)
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            }
        ).catch((e) => {
            console.log(e)
        });
    };

    return <FlexMainPage sx={{
        backgroundImage: `url(/7148504.jpg)`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
    }}>
        <Box display={"flex"} flexDirection={"column"} flexGrow={1} justifyContent={"center"} alignItems={"center"}>
            <PaperItem key={"login_panel"} elevation={6}
                       sx={{borderRadius: "15px", backgroundColor: "white"}}>
                <Box display={"flex"} flexDirection={"column"} gap={1} p={4} alignItems={"center"}>
                    <Typography variant="h2" color={"black"}>
                        Maybe wine?
                    </Typography>
                    <Typography variant="h6" color={"black"}>
                        {t("Приложение для слепых винных дегустаций")}
                    </Typography>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={2} paddingTop={1}>
                        <GoogleOAuthProvider clientId='771561463179-7mo3g7rs5119vldk534lugs988cj2fh8.apps.googleusercontent.com'>
                            <GoogleLogin onSuccess={onGoogleSuccess} onError={onGoogleError}/>
                        </GoogleOAuthProvider>
                        <Button sx={{minWidth: '100%',}}
                                onClick={onAnonymousLogin}
                                fullWidth
                                variant="contained">{t("Войти анонимно")}</Button>
                    </Box>
                </Box>
            </PaperItem>

        </Box>
    </FlexMainPage>
}
import React, {useState} from 'react';
import {
    Button,
    Box,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
    Fab
} from "@mui/material";
import {setNextWine} from "../requests/api";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {UserAvatar} from "../help/avatar";
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';

export function SetNextWineBtn() {

    const {currentUser, t} = useDefaultContext();
    const {wines, uuid, state, owner, defaultNextWine} = useWSContext();
    const getFilteredWines = () => wines?.filter(wine => wine.state === "CLOSED") || []
    const hasClosed = getFilteredWines()?.length > 0;
    const isOwner = currentUser.uuid === owner.uuid;
    const [wine, setWine] = useState(defaultNextWine);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setWine(defaultNextWine)
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
    };


    const handleSubmit = () => {
        const wineUUID = !wine ? defaultNextWine?.uuid : wine?.uuid;
        setNextWine(uuid, wineUUID).then(r => {
        })
    }

    return (<Box textAlign='center'>
        <Tooltip title={t("Операция доступна только владельцу дегустации")}>
                    <span>
         {(state === "CREATED" || hasClosed) && <Fab size="large" color="success" onClick={handleClickOpen}
                                                     disabled={!isOwner ? true : !hasClosed}><PlayArrowSharpIcon/></Fab>}

                        {(state === "STARTED" && !hasClosed) && <Fab size="large" color="success" onClick={handleSubmit}
                                                                     disabled={!isOwner}><PlayArrowSharpIcon/></Fab>}

                    </span>
        </Tooltip>
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle textAlign={"center"}>{t("Выбор вина")}</DialogTitle>
            <DialogContent dividers>
                {hasClosed ? <FormControl sx={{width: "100%"}}><Select id="wine-select"
                                                                       fullWidth
                                                                       value={wine}
                                                                       onChange={(e) => {
                                                                           setWine(e.target.value)
                                                                       }}>
                    {getFilteredWines().map(item => <MenuItem key={item.uuid} value={item}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}
                             width={"100%"}>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}
                                 flexGrow={1}>
                                <Typography sx={{
                                    maxWidth: "50vw",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "3",
                                    WebkitBoxOrient: "vertical",
                                    wordBreak: 'break-word',
                                }}>{item.name}<br/><small>{t("Вино от")} {item.owner.username}</small>
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <UserAvatar user={item.owner} size={64}/>
                            </Box>
                        </Box>
                    </MenuItem>)}
                </Select>
                </FormControl> : <Typography>{t("Вы не добавили ни одного вина")}</Typography>}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="contained" color={"error"}
                        fullWidth
                        onClick={handleClose}>{t("Закрыть")}</Button>
                {(state === 'STARTED' && !hasClosed) ? <Button onClick={handleSubmit}
                                                               fullWidth
                                                               variant="contained">{t("Завершить дегустацию")}</Button> :
                    <Button onClick={handleSubmit}
                            fullWidth
                            disabled={!hasClosed}
                            variant="contained">{t("Далее")}</Button>}

            </DialogActions>
        </Dialog>
    </Box>);
}
import http from "./http";
import {getUserName} from "../help/storageUtils";
import {getAvatar} from "../help/avatar";

export const loadGoogleUser = () => {
    return http({
        method: 'GET',
        url: '/auth/findUser',
        credentials: 'include',
    });
};

export const postLoginToken = (idToken, type) => {
    const data = {
        accountType: type,
        idToken: idToken
    };
    return http({
        method: 'POST',
        url: '/auth/login',
        credentials: 'include',
        data: JSON.stringify(data),
    });
};

export const generateAnonToken = () => {
    let oldToken = localStorage.getItem("anonToken");
    const data = {
        oldToken: oldToken,
        avatar: getAvatar(),
        username: getUserName()
    };
    return http({
        method: 'POST',
        url: '/auth/generate-anon-token',
        data: JSON.stringify(data),
    }).then(response => {
        localStorage.setItem("anonToken", response)
    })
}

export const logout = () => {
    return http({
        method: 'POST',
        url: '/auth/logout',
        credentials: 'include',
    });
};

export const editUser = (user) => {
    return http({
        method: 'POST',
        url: '/auth/edit',
        credentials: 'include',
        data: user
    });
};


export const createDegustation = () => {
    return http({
        method: 'POST',
        url: '/degustation/create',
        credentials: 'include',
    });
};

export const joinDegustation = (uuid) => {
    return http({
        method: 'POST',
        url: `/degustation/join?uuid=${uuid || ''}`,
        credentials: 'include',
    });
};

export const findDegustations = () => {
    return http({
        method: 'POST',
        url: `/degustation/get-all`,
        credentials: 'include',
    });
};

export const addWine = (uuid, request) => {
    return http({
        url: `/degustation/add-wine?uuid=${uuid}`,
        method: "POST",
        credentials: 'include',
        data: request,
    })
}

export const deleteWine = (uuid, uuidWine) => {
    return http({
        url: `/degustation/delete-wine?uuid=${uuid}&wine=${uuidWine}`,
        credentials: 'include',
        method: 'POST',
    })
}

export const openWine = (uuid, uuidWine) => {
    return http({
        url: `/degustation/open-wine?uuid=${uuid}&wine=${uuidWine}`,
        credentials: 'include',
        method: 'POST',
    })
}

export const setNextWine = (uuid, uuidWine) => {
    const part = uuidWine ? `&wine=${uuidWine}` : ``
    return http({
        url: `/degustation/set-next-wine?uuid=${uuid}${part}`,
        credentials: 'include',
        method: 'POST',
    })
}

export const addAnswer = (uuid, request) => {
    return http({
        url: `/degustation/add-answer?uuid=${uuid}`,
        method: "POST",
        credentials: 'include',
        data: request,
    })
}

export const loadGrapesDictionary = (action) => {
    http({
        url: '/dictionary/grape/find-all', method: 'GET',
    }).then(response => {
        action(response)
    })
}

export const loadCountriesDictionary = (action) => {
    http({
        url: '/dictionary/country/find-all', method: 'GET',
    }).then(response => {
        action(response)
    })
}

export const loadRegionsDictionary = (action) => {
    http({
        url: '/dictionary/region/find-all', method: 'GET',
    }).then(response => {
        action(response)
    })
}

import React, {useEffect} from "react";
import {AddWinePanel} from "../components/wine/AddWinePanel";
import {AddAnswerPanel} from "../components/answer/AddAnswerPanel";
import {FinalResultPanel} from "./FinalResultPanel";
import {LevelResultPanel} from "./LevelResultPanel";
import {
    useDefaultContext,
    useWSContext,
    WSProvider
} from "../context/WSContext";
import {useNavigate, useParams} from "react-router-dom";

export const DegustationPage = () => {

    const {isLogin} = useDefaultContext();
    const navigate = useNavigate();
    const {degustationUUID} = useParams();

    useEffect(() => {
        if (!isLogin) {
            navigate("/login", {
                state: {degustationUUID: degustationUUID}
            })
        }
    }, [isLogin, degustationUUID]);

    function DegustationPanel() {
        const {state, currentWine} = useWSContext()

        if (state === 'CREATED') {
            return <AddWinePanel/>
        }
        if (state === 'STARTED') {
            if (currentWine?.state === "CLOSED") {
                return <AddAnswerPanel/>
            } else return <LevelResultPanel/>
        }
        if (state === "COMPLETED") return <FinalResultPanel/>
    }

    return (<WSProvider uuid={degustationUUID}>
        <DegustationPanel/>
    </WSProvider>);
}

export default DegustationPage;
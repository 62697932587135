import i18n from "i18next";
import InitBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {getLanguage} from "./help/lang";

i18n
    .use(InitBackend)
    .use(initReactI18next)
    .init({
        backend: { backends: [HttpBackend] },
        fallbackLng: getLanguage(),
        interpolation: {
            escapeValue: false,
        },
        load: "languageOnly",
        returnObjects: true,
        debug: true,
    });

i18n.services.formatter.add("lowercase", (value) => value.toLowerCase());

export default i18n;

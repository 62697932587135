import React, {Fragment, useEffect, useState} from "react";
import {Box, Fab, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useParams} from "react-router-dom";
import {useDefaultContext} from "../context/WSContext";
import {createDegustation, findDegustations} from "../requests/api";
import {
    BottomActionsPanel, FlexTablePanel,
    PaperItem,
} from "../components/base";
import {UserAvatar} from "../help/avatar";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";

export const DashboardPage = () => {

    const {isLogin, t} = useDefaultContext();
    const [degustations, setDegustations] = useState([])

    const navigate = useNavigate();
    const params = useParams();
    const degustationUUID = params.degustationUUID;

    useEffect(() => {
        if (!isLogin) {
            navigate("/login", {
                state: {degustationUUID: degustationUUID}
            })
        } else {
            findDegustations().then(response => {
                setDegustations(response)
            })
        }
    }, [isLogin, navigate, degustationUUID]);

    const createDegustationBtn = () => {
        createDegustation()
            .then(response => {
                navigate(`/${response.uuid}`)
            })
    }

    return <Fragment>
        <FlexTablePanel emptyMessage={`${t("Здесь будут ваши дегустации")} 🍷`}>
            {
                degustations.map(degustation => {
                    return <PaperItem key={degustation.uuid} elevation={6}>
                        <Box display={"flex"} flexDirection={"row"} p={2} gap={2}>
                            <Box sx={{all: "initial"}}>
                                <UserAvatar user={degustation.owner} size={64}/>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} flexGrow={1}
                                 justifyContent={"center"} alignItems={"flex-start"}>
                                <Typography>{`${t("Дата создания")}:`} {new Date(degustation.createDate).toLocaleDateString()}</Typography>
                                <Typography>{`${t("Статус")}:`} {t(degustation.state)}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                <IconButton size="small" aria-label="open degustation"
                                            onClick={() => navigate(`/${degustation.uuid}`)}><VisibilitySharpIcon/></IconButton>
                            </Box>
                        </Box>
                    </PaperItem>
                })
            }
        </FlexTablePanel>
        <BottomActionsPanel>
            <Fab size="large" color="primary" aria-label="add" onClick={createDegustationBtn}>
                <AddIcon/>
            </Fab>
        </BottomActionsPanel>
    </Fragment>
}
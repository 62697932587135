import * as React from 'react';
import {Box, Divider, IconButton, Tooltip, ListItemIcon, MenuItem, Menu, Button} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from "react-router-dom";
import {useDefaultContext} from "../context/WSContext";
import {UserAvatar} from "../help/avatar";
import {logout} from "../requests/api";

export const AccountMenu = () => {
    const navigate = useNavigate();
    const {t, currentUser, setCurrentUser, setIsLogin} = useDefaultContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenProfile = () => {
        handleClose()
        navigate("/profile")
    };

    const logoutHandle = () => {
        logout().then(response => {
            setCurrentUser({})
            setIsLogin(false)
            navigate()
        })
    };

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title={t("Меню")}>
                    <IconButton onClick={handleClick}
                                size="small"
                                sx={{ml: 2}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}>
                        <Box sx={{all: "initial"}}>
                            <UserAvatar user={currentUser} size={48}/>
                        </Box>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                <MenuItem onClick={handleOpenProfile}>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    {t("Профиль")}
                </MenuItem>
                <Divider/>
                {
                    currentUser?.uuid && <MenuItem onClick={() => navigate("/")}>
                        <ListItemIcon>
                            <ListSharpIcon/>
                        </ListItemIcon>
                        {t("Мои дегустации")}
                    </MenuItem>
                }
                <Divider/>
                <MenuItem onClick={logoutHandle}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    {t("Выход")}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}